<!-- 医生管理 -->
<template>
	<div class="doctor_management">
		<el-row>
			<el-col :span="24">
				<div class="grid-content">
					<template>
						<div class="form-style">
							<el-form ref="formInline" :inline="true" :model="formInline" label-width="150px" class="demo-form-inline">
								<el-form-item label="医生姓名" prop="statisticsName ">
									<el-input clearable v-model="formInline.statisticsName " placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item>
									<el-button type="primary" @click="querys">搜索</el-button>
									<el-button @click="resetForm('formInline')">重置</el-button>
									<el-button @click="CheckPatient">查看该医生的患者</el-button>
								</el-form-item>
							</el-form>
						</div>
						<div class="tableMargin">
							<el-table @selection-change="handleSelectionChange" ref="multipleTable" :header-cell-style="{background:'#eef1f6'}"
							 :data="tableData" style="width: 100%">,
								<el-table-column type="selection" width="55">
								</el-table-column>
								<el-table-column align="center" prop="statisticsName" label="医生姓名">
								</el-table-column>
								<el-table-column align="center" prop="statisticsPeriodHead" label="特长">
								</el-table-column>
							</el-table>
						</div>
						<v-paging v-on:childPage="pages" :total=total></v-paging>
					</template>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
    import vPaging from '../common/Paging.vue' //引入分页组件
	export default {
		components: {
			vPaging
		},
		methods: {
			//分页组件
			pages(current, size) {
				
			},
			//查看该医生的都患者
			CheckPatient(){
				this.$router.push({
				    path: '/DoctorsPatient'
				})
			},
			// 搜索
			querys() {

			},
			//重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			// 选中事件
			handleSelectionChange(val) {
				this.multipleSelection = val;
			}
		},
		data() {
			return {
				// 选中数据
				multipleTable: [],
				//form表单查询
				formInline: {},
				//table列表
				tableData: [],
				//总条数
				total: 0,
			}
		},
		created() {},
		mounted() {}
	}
</script>

<style scoped>
</style>
